//
// Copyright 2021 Luxembourg Institute of Science and Technology (LIST - http://www.list.lu/).
//
// Author: Olivier Parisot (olivier.parisot@list.lu)
//

import React from 'react';

import { ModalBody } from 'reactstrap';

import contentHelper from './contentHelper.js';


const bibSection=
            (
              <span>
              Eickermann M., Ronellenfitsch F. K. , and Junk, J. (2020): Developing a decision support tool to forecast the abundance of the cabbage stem weevil in winter oilseed rape. Plant Protection Science, vol. 56, no. 4.
              &nbsp;
              <a rel="noopener noreferrer" target="_blank" href="https://doi.org/10.17221/93/2019-PPS">https://doi.org/10.17221/93/2019-PPS</a>
              </span>
            );

const referencesFR=
            (
              <ModalBody>
              <small>
              {contentHelper.appDesc(contentHelper.fr())}
              <br/>
              <br/>
              <h5>{contentHelper.referencesLabel(contentHelper.fr())}</h5>
              {bibSection}
              </small>
              </ModalBody>
            );

const referencesDE=
            (
              <ModalBody>
              <small>
              {contentHelper.appDesc(contentHelper.de())}
              <br/>
              <br/>
              <h5>{contentHelper.referencesLabel(contentHelper.de())}</h5>
              {bibSection}
              </small>
              </ModalBody>
            );

const referencesEN=
            (
              <ModalBody>
              <small>
              {contentHelper.appDesc(contentHelper.en())}
              <br/>
              <br/>
              <h5>{contentHelper.referencesLabel(contentHelper.en())}</h5>
              {bibSection}
              </small>
              </ModalBody>
            );

const referencesLU=
            (
              <ModalBody>
              <small>
              {contentHelper.appDesc(contentHelper.lu())}
              <br/>
              <br/>
              <h5>{contentHelper.referencesLabel(contentHelper.lu())}</h5>
              {bibSection}
              </small>
              </ModalBody>
            );

const ReferencesModalBody = ({ lang }) =>
{
  if (lang===contentHelper.en()) return (referencesEN);
  else if (lang===contentHelper.fr()) return (referencesFR);
  else if (lang===contentHelper.de()) return (referencesDE);
  else if (lang===contentHelper.lu()) return (referencesLU);
  else return <br/>;
};

export default ReferencesModalBody;
