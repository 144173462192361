//
// Copyright 2022 Luxembourg Institute of Science and Technology (LIST - http://www.list.lu/).
//
// Author: Philippe Pinheiro (philippe.pinheiro@list.lu)
//

import React from 'react';
import * as d3 from "d3/dist/d3";
import rd3 from 'react-d3-library';
import moment from 'moment';

import PropTypes from "prop-types";
const RD3Component = rd3.Component;

class StackedChart extends React.Component {

  margin = {top: 20, right: 50, bottom: 40, left: 70};
  width = 600 - this.margin.left - this.margin.right;
  height = 400 - this.margin.top - this.margin.bottom;

  constructor(props) {
    super(props);
    this.state = {d3: ''}
    this.d3Div = document.createElement('div');
    this.d3Div.style.width = "600px";
    this.d3Div.style.height = "400px"; //TODO const plotHeight=window.innerHeight/2.5;
    this.timeseries = props.timeseries;
    this.barcharts = props.barcharts;
    this.resultsLabel = props.resultsLabel;
    this.leftYAxisLabel = props.leftYAxisLabel;
    this.rightYAxisLabel = props.rightYAxisLabel;
  }

  componentDidMount() {
    this.setState({d3: this.d3Div});
    this.initChart(d3.extent(this.timeseries[0].data,function(d) { return d.time; }));
    this.timeseries.forEach(timeserie => this.addTimeserieChart(timeserie));
    this.barcharts.forEach(barchart => this.addBarChart(barchart));
  }

  render() {
    return (
      <div>
        <RD3Component data={this.state.d3} />
      </div>
    )
  }

  initChart(extent) {
    // append the svg object to the body of the page
    this.svg = d3.select(this.d3Div).append("svg")
      .attr("width", this.width + this.margin.left + this.margin.right)
      .attr("height", this.height + this.margin.top + this.margin.bottom)
      .append("g")
      .attr("transform", `translate(${this.margin.left},${this.margin.top})`);

    var that = this;
    // Add left Y axis
    this.leftY = d3.scaleLinear()
      //.domain([0, d3.max(data, function(d) { return +d.value; })])
      .domain([-20, 40])
      .range([ this.height, 0 ]);
    this.svg.append("g")
      //.attr("transform", `translate(${-10}, 0)`)
      .call(d3.axisLeft(this.leftY).tickValues([-10,0,10,20,30]));
    this.svg.append('text')
      .attr('text-anchor', 'middle')
      .attr('font-size','10px')
      .attr("x",20-this.height/2)
      .attr("y",-40)
      .attr('transform', 'rotate(-90)')
      .text(that.leftYAxisLabel);
    this.svg.append('text')
      .attr('text-anchor', 'middle')
      .attr('font-size','10px')
      .attr("x",-25)
      .attr("y",this.height-15)
      .text(that.resultsLabel + " : ");

    // Add right Y axis
    this.rightY = d3.scaleLinear()
      //.domain([0, d3.max(data, function(d) { return +d.value; })])
      //.domain([0, 50])
      .domain([-7, 50])
      .range([ this.height, 0 ]);
    this.svg.append("g")
      .attr("transform", `translate(${this.width}, 0)`)
      .call(d3.axisRight(this.rightY).tickValues([0,5,10,15]));
    this.svg.append('text')
      .attr('text-anchor', 'middle')
      .attr('font-size','13px')
      .attr("x",30+this.height/2)
      .attr("y",-this.width-40)
      .attr('transform', 'rotate(90)')
      .text(that.rightYAxisLabel);

    // Add X axis
    this.x = d3.scaleTime()
      .domain([extent[0]-(6*60*60*1000),extent[1]+(6*60*60*1000)])
      .range([ 0, this.width ]);
    this.xWidth = d3.timeDay.count(new Date(extent[0]),new Date(extent[1]))/this.width;
    this.svg.append("g")
      .attr("width",`${this.width}`)
      .attr("transform", `translate(0, ${that.rightY(0)})`)
      .call(d3.axisBottom(this.x).tickFormat("").tickSize(0));
    this.svg.append("g")
      .attr("width",`${this.width+20}`)
      .attr("transform", `translate(0, ${this.height})`)
      .call(d3.axisBottom(this.x)
              .tickFormat(d => {
                let date = moment(d);
                return date.hour()===12?date.format("DD/MM"):'';
              }));

    this.tooltip = d3.select("#tooltip")
    if(this.tooltip.empty()) {
      this.tooltip = d3.select("body").append("div").attr("id","tooltip")
                       .attr("style", "position:absolute;width:100px;height:24px;font-size:12px;padding-left:10px;" +
                                      "border-radius: 5px;border: 2px solid grey;" +
                                      "pointer-events: none;background:#eeeeee;opacity:0");
    }

    var legendX = 25;
    var legendY = -5;
    this.timeseries.forEach((timeserie,index) => {
      this.svg.append("rect")
        .attr('x',legendX)
        .attr('y',legendY)
        .attr('width',10)
        .attr('height',5)
        .attr('fill',timeserie.color);
      this.svg.append("text")
        .attr('x',legendX+15)
        .attr('y',legendY+6)
        .attr('font-size',10)
        .text(timeserie.label);
      legendX += 150;
      if (index === 2) {
        legendX = 25
        legendY = 10
      }
    });
    //legendX = 30;
    legendY = 10;
    this.barcharts.forEach((barchart) => {
      this.svg.append("rect")
        .attr('x',legendX)
        .attr('y',legendY)
        .attr('width',10)
        .attr('height',5)
        .attr('fill',barchart.color);
      this.svg.append("text")
        .attr('x',legendX+15)
        .attr('y',legendY+6)
        .attr('font-size',10)
        .text(barchart.label);
      legendX += 150;
    });

  }

  addTimeserieChart(timeserie) {
    var that = this;

    // Add the line
    this.svg.append("path")
          .datum(timeserie.data)
          .attr("class", "line test")
          .attr("fill", "none")
          .attr("stroke", timeserie.color)
          .attr("stroke-width", 1.5)
          .attr("d", d3.line()
            .x(function(d) { return that.x(d.time) })
            .y(function(d) { return that.leftY(d.value) })
          );

    timeserie.data.forEach(function(d) {
      that.svg.append("circle")
        .attr("r", 2)
        .attr("cx", that.x(d.time))
        .attr("cy", that.leftY(d.value))
        .style("fill", timeserie.color)
        .style("stroke", timeserie.color)
        .on("mouseover", function(event) {
          let coords = d3.pointer(event,d3.select("body"));
          that.tooltip.html(moment(d.time).format("DD/MM HH:mm") + " " +
                            timeserie.label + " : " + d.value + timeserie.unit )
            .style("left", (coords[0]) + "px")
            .style("top", (coords[1]-31) + "px")
            .style("width","260px")
            .style("opacity", 1);
        })
        .on("mouseleave", function() {
          that.tooltip.style("left",0).style("top",0)
            .style("opacity", 0);
        });
    });
  }

  addBarChart(barchart) {
    var that = this;
    barchart.data.forEach(function(d) {
      if(barchart.unit===undefined){
        that.svg.append("rect")
          .attr("r", 1)
          .attr("x", that.x(d.time)-43*60*that.xWidth)
          .attr("y", that.rightY(0)+3)
          .attr("width", 86*60*that.xWidth)
          .attr("height", 36)
          .style("fill", d.resbool?'rgba(255,0,0,1)':'rgba(0,255,0,1)')
          .style("stroke", d.resbool?'rgba(255,0,0,1)':'rgba(0,255,0,1)')
          .on("mousemove", function(event) {
            let coords = d3.pointer(event,d3.select("body"));
            that.tooltip.html(d.resbool?"Expected activity of insects":"No activity of insects")
                .style("left", (coords[0]) + "px")
                .style("top", (coords[1]-31) + "px")
                .style("width","200px")
                .style("opacity", 1);
          })
          .on("mouseleave", function() {
            that.tooltip.style("left",0).style("top",0)
                .style("opacity", 0);
          });
      } else {
        //DEBUG:Simulate rain/////////////////
        //d.value = Math.round(Math.random(3))+1;
        //DEBUG////////////////////
        that.svg.append("rect")
          .attr("r", 1)
          .attr("x", that.x(d.time)-3*60*that.xWidth)
          .attr("y", that.rightY(d.value))
          .attr("width", 7*60*that.xWidth)
          .attr("height", that.rightY(0)-that.rightY(d.value))
          .style("fill", barchart.color)
          .style("stroke", barchart.color)
          .on("mousemove", function(event) {
            let coords = d3.pointer(event,d3.select("body"));
            that.tooltip.html(moment(d.time).format("DD/MM HH:mm") + " " + barchart.label + " : " + d.value + " mm" )
                .style("left", (coords[0]) + "px")
                .style("top", (coords[1]-31) + "px")
                .style("width","250px")
                .style("opacity", 1);
          })
          .on("mouseleave", function() {
            that.tooltip.style("left",0).style("top",0)
              .style("opacity", 0);
          });
      }
    });
  }
}

StackedChart.propTypes = {
  resultsLabel: PropTypes.string,
  timeseries: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      data: PropTypes.shape({
        time: PropTypes.number,
        value: PropTypes.number
      }),
      color: PropTypes.string
    })
  ).isRequired
}

export default StackedChart;