//
// Copyright 2021-2024 Luxembourg Institute of Science and Technology (LIST - http://www.list.lu/).
//
// Author: Olivier Parisot (olivier.parisot@list.lu)
//

import React, { Component } from 'react';
import './App.css';

//import { geolocated } from "react-geolocated";

//import { Tabs } from 'react-simple-tabs-component'
import 'react-simple-tabs-component/dist/index.css'

import { Map, TileLayer, Marker, CircleMarker, Tooltip } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';

import { UncontrolledTooltip, Container, Row, Col, Button, ButtonGroup, Modal, ModalHeader, ModalFooter} from 'reactstrap';
import LoadingOverlay from 'react-loading-overlay';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import 'bootstrap/dist/css/bootstrap.min.css';

import moment from 'moment';

import {point, featureCollection, nearestPoint} from '@turf/turf';

import UserGuideModalBody from './UserGuideModalBody.js';
import ReferencesModalBody from './ReferencesModalBody.js';
import DisclaimerModalBody from './DisclaimerModalBody.js';
import PrivacyModalBody from './PrivacyModalBody.js';

//import TimeSeriesChart from './TimeSeriesChart.js';
//import Histogram from './Histogram.js';
import StackedChart from './StackedChart.js';

//import Slider from 'rc-slider';
//import 'rc-slider/assets/index.css';

import contentHelper from './contentHelper.js';

import Control from 'react-leaflet-control';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { faCrosshairs, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { faInfoCircle, faSignOutAlt, faSync } from '@fortawesome/free-solid-svg-icons';


import ReactFlagsSelect from 'react-flags-select';
import 'react-flags-select/css/react-flags-select.css';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { registerLocale, setDefaultLocale } from  "react-datepicker";
import fr from 'date-fns/locale/fr';
import de from 'date-fns/locale/de';
import lb from 'date-fns/locale/lb';
import en from 'date-fns/locale/en-GB';

import axios from 'axios';

import bcrypt from 'bcryptjs';

import L from 'leaflet';
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});


registerLocale('en', en)
registerLocale('fr', fr)
registerLocale('lb', lb)
registerLocale('de', de)

const saltRounds=10;

const debugMode=false;


const centerLatitude=49.762;
const centerLongitude=6.11;
const defaultZoom=9.6;



const logoDiv=
        (<span>
        <img style={{display: "inline-block", width: "125px"}} alt="WEEVIL logo" src={process.env.PUBLIC_URL+'/weevil-logo.png'} />
        &nbsp;
        <a className="App-link" rel="noopener noreferrer" target="_blank" href="http://www.list.lu/">
          <img style={{display: "inline-block", width: "125px"}} alt="LIST logo" src={process.env.PUBLIC_URL+'/list_logo.png'} />
        </a>
        </span>);




/**
 * App.
 *
 * Author: Olivier Parisot
 */
class App extends Component
{
  /** Map reference. */
  map;

  constructor(props)
  {
    super(props);

    this.state =
    {
      lang: contentHelper.defaultLang(),
      errorMsg: null,
      loading: false,
      weatherData: null,
      mailleId: null,
      weatherDataAsFeatureCollection: null,
      forecastDateAsMoment: moment().hour()<9?moment().subtract(1,"day"):moment(),
      charSeries: null,
      latitude: centerLatitude,
      longitude: centerLongitude,
      zoom: defaultZoom,
      zoomSnap: 0.1,
      showUserGuide: false,
      showReferences: false,
      showDisclaimer: false,
      showPrivacy: false,
      userName: null,
      userNameTmp: null,
      hashTmp: null
    };

    this.onMapClick=this.onMapClick.bind(this);

  }


  getHeader = () =>
  {
    return (
      <span style={{display: "inline-block"}}>
        {logoDiv}
        {this.getLangSelect()}
      </span>
    );
  }

  getDefaultCountry = () =>
  {
    //console.info("getDefaultCountry()");
    if (this.state.lang===contentHelper.en()) return "GB";
    else if (this.state.lang===contentHelper.fr()) return "FR";
    else if (this.state.lang===contentHelper.de()) return "DE";
    else if (this.state.lang===contentHelper.lu()) return "LU";
  }

  getLangSelect = () =>
  {
    return (
      <ReactFlagsSelect
         key={"react-flags-select-"+Math.floor(Math.random()*1001)}
         countries={["GB", "FR", "DE", "LU"]}
         placeholder="Language"
         defaultCountry={
          this.getDefaultCountry()
         }
         selectedSize={15}
         optionsSize={12}
         showOptionLabel={false}
         showSelectedLabel={false}
         onSelect={(countryCode)=>{
           //console.info(countryCode);
           if (countryCode==="GB") {this.setState({lang: contentHelper.en()});setDefaultLocale('en');}
           else if (countryCode==="FR") {this.setState({lang: contentHelper.fr()});setDefaultLocale('fr');}
           else if (countryCode==="DE") {this.setState({lang: contentHelper.de()});setDefaultLocale('de');}
           else if (countryCode==="LU") {this.setState({lang: contentHelper.lu()});setDefaultLocale('lb');}
         }}
      />
    );
  }

  onMapClick = (e) =>
  {
      this.setState({mailleId: null, latitude: e.latlng.lat, longitude: e.latlng.lng},()=>
        {
          this.updateNearestMailleId();
        }
      );
  }

  componentDidMount= () =>
  {
    this.loadForecastData(this.state.forecastDateAsMoment.format('DDMMYYYY'));
  }

  updateNearestMailleId = () =>
  {
    //console.info("updateNearestMailleId()");
    const targetPoint=point([this.state.latitude,this.state.longitude],{});
    const nearestP=nearestPoint(targetPoint,this.state.weatherDataAsFeatureCollection);
    //toast.info("Nearest "+nearestP.properties.mailleId+" ("+nearestP.properties.distanceToPoint+")!", {position: toast.POSITION.TOP_LEFT});
    this.setState({mailleId: nearestP.properties.mailleId});
    //return {mailleId: nearestP.properties.mailleId, distance: Math.round(nearestP.properties.distanceToPoint)};
  }

  buildStationsMarkers = () =>
  {
    //console.info("buildStationsMarkers() "+this.state.mailleId);
    var markers=[];
    if (this.state.weatherData!==null)
    {
      const array0=this.state.weatherData;
      for (var i=0;i<array0.length;i++)
      {
          const isSelected=(i===this.state.mailleId);
          markers.push(
            <CircleMarker
                  key={"circleStation_"+i+"_"+this.state.mailleId}
                  color={isSelected?"blue":"black"}
                  fill={true}
                  center={[array0[i].latitude,array0[i].longitude]}
                  radius={isSelected?8:4} >
               <Tooltip>{array0[i].station}</Tooltip>
            </CircleMarker>
          );
      }
    }
    return markers;
  }

  componentDidUpdate = (prevProps, prevState, snapsho) =>
  {
      if (this.state.userName!==null && this.map!==null) this.map.leafletElement.invalidateSize();
  }

  localizeMe = () =>
  {
    if (this.props.coords!==null)
    {
      //console.info(this.props.coords);
      this.setState({latitude: this.props.coords.latitude, longitude: this.props.coords.longitude},()=>{this.updateNearestMailleId()});
    }
  }

  loadForecastData = (ddate) =>
  {
    console.info("loadForecastData() "+ddate);
    this.setState({loading: true, weatherData: null, weatherDataAsFeatureCollection: null});
    const jsonFn="/data/weather/forecast_"+ddate+".json";
    //const jsonFn="http://erp-geodb.private.list.lu:8080/weevil/forecast/"+ddate;
    console.info(jsonFn);
    fetch(jsonFn)
      .then(r =>
      {
        return r.json();
      })
      .then(data0 =>
      {
        console.info(data0);
        var maillesPointsArray=[];
        var chartSeries=[];
        const data=data0['predictions'];
        console.info(data);
        for (var i=0;i<data.length;i++)
        {
          console.info(data[i]);
          data[i].mailleId=i;
          if (data[i].latitude!==null)
          {
            maillesPointsArray.push(point([data[i].latitude,data[i].longitude],{mailleId:i}));
          }
        }
        const maillesAsFeatureCollection=featureCollection(maillesPointsArray);
        //toast.info("Loaded "+maillesPointsArray.length+" stations from "+jsonFn+" !", {position: toast.POSITION.TOP_LEFT});
        this.setState({weatherData: data, weatherDataAsFeatureCollection: maillesAsFeatureCollection, chartSeries: chartSeries, loading: false});
        this.updateNearestMailleId();
        this.updateNearestMailleId();
      })
      .catch(error =>
      {
        console.error(error);
        const errMsg="Impossible to open "+jsonFn+" !";
        this.setState({loading: false, errorMsg: errMsg});
        toast.error(errMsg, {position: toast.POSITION.TOP_LEFT});
      });
  }

  startTimestamp = (wData,mId) =>
  {
    if (wData!==null&&mId!==null)
    {
      const array0=wData[mId];
      const currDayString=array0.timestamps[0];
      var currDateRes=moment(currDayString,"DDMMYYYY");
      currDateRes=currDateRes.set({hour:0,minute:0,second:0,millisecond:0});
      const currTimestampRes=currDateRes.unix()*1000;
      return currTimestampRes;
    }
    return -1;
  }

  endTimestamp = (wData,mId) =>
  {
    if (wData!==null&&mId!==null)
    {
      const array0=wData[mId];
      const currDayString=array0.timestamps[array0.timestamps.length-1];
      var currDateRes=moment(currDayString,"DDMMYYYY");
      currDateRes=currDateRes.set({hour:23,minute:59,second:0,millisecond:0});
      const currTimestampRes=currDateRes.unix()*1000;
      return currTimestampRes;
    }
    return -1;
  }

  buildChartSeries = (wData,mId) =>
  {
      console.info("buildChartSeries() "+mId);

      var chartSeries={};
      chartSeries.temperatures=[];
      chartSeries.temperaturessoil=[];
      chartSeries.precipitations=[];
      chartSeries.windspeed=[];
      chartSeries.sunshineduration=[];
      chartSeries.results=[];

      //const mailleId=this.state.mailleId;
      if (wData!==null&&mId!==null)
      {
        const array0=wData[mId];
        //console.info(array0);
        for (var i=0;i<array0.timestamps.length;i++)
        {
          const currI=i;
          const currDayString=array0.timestamps[currI];

          // res
          const tasMax=Math.max(array0["6"]["TA200"][currI],array0["12"]["TA200"][currI],array0["18"]["TA200"][currI]);
          const tsoilMax=Math.max(array0["6"]["TS005"][currI],array0["12"]["TS005"][currI],array0["18"]["TS005"][currI]);
          const precip=array0["6"]["Precip100"][currI]+array0["12"]["Precip100"][currI]+array0["18"]["Precip100"][currI];
          //const sd=(12/3)*(array0["6"]["SSD200"][currI]+array0["12"]["SSD200"][currI]+array0["18"]["SSD200"][currI]);
          const sd=array0["daily"]["SSD200"][currI];
          const windMax=Math.max(array0["6"]["WV1000"][currI],array0["12"]["WV1000"][currI],array0["18"]["WV1000"][currI]);
          const l1=tasMax>7.8&&tsoilMax>=6.6;
          const l2=precip<1&&sd>=2.5&&windMax<=3;
          const res=l1&&l2;
          console.info("tasMax="+tasMax+" tsoilmax="+tsoilMax+" precip="+precip+" sunshineDuration="+sd+" windMax="+windMax+" res="+res);
          var currDateRes=moment(currDayString,"DDMMYYYY");
          currDateRes=currDateRes.set({hour:12,minute:0,second:0,millisecond:0});
          const currTimestampRes=currDateRes.unix()*1000;
          chartSeries.results.push({resbool: res, value: 1, time: currTimestampRes});

          // weather data
          ["6","12","18"].forEach(function (hour)
          {
            const currTemp=array0[hour]["TA200"][currI];
            const currTempSoil=array0[hour]["TS005"][currI];
            const currPrecip=array0[hour]["Precip100"][currI];
            const currWindspeed=array0[hour]["WV1000"][currI];
            const currSunchineduration=array0[hour]["SSD200"][currI];
            var currDate=moment(currDayString,"DDMMYYYY");
            currDate.set({hour:hour,minute:0,second:0,millisecond:0});
            const currTimestamp=currDate.unix()*1000;
            chartSeries.temperatures.push({resbool: res, value: currTemp, time: currTimestamp});
            chartSeries.temperaturessoil.push({resbool: res, value: currTempSoil, time: currTimestamp});
            chartSeries.precipitations.push({resbool: res, value: currPrecip, time: currTimestamp});
            chartSeries.windspeed.push({resbool: res, value: currWindspeed, time: currTimestamp});
            chartSeries.sunshineduration.push({resbool: res, value: currSunchineduration, time: currTimestamp});
          });

        }

        console.info("---------------");
        console.info("temperatures "+chartSeries.temperatures.length);
        console.info("temperaturessoil "+chartSeries.temperaturessoil.length);
        console.info("precipitations "+chartSeries.precipitations.length);
        console.info("windspeed "+chartSeries.windspeed.length);
        console.info("sunshineduration "+chartSeries.sunshineduration.length);
        console.info("results "+chartSeries.results.length);
        console.info("---------------");
      }

      return chartSeries;
  }

  nearestStationName = () =>
  {
    const mailleId=this.state.mailleId;
    if (this.state.weatherData!==null&&mailleId!==null)
    {
      return this.state.weatherData[mailleId].station;
    }
    else return "";
  }

  getButtonsGroup = () =>
  {
    return (
      <div style={{textAlign: "center"}}>
      <ButtonGroup>
      <Button className="App-button" outline size="sm" onClick={()=>{this.setState({showReferences: false, showUserGuide: true, showDisclaimer: false, showPrivacy: false})}}>
        <small>{contentHelper.userGuideLabel(this.state.lang)}</small>
      </Button>
      &nbsp;&nbsp;
      <Button className="App-button" outline size="sm" onClick={()=>{this.setState({showReferences: true, showUserGuide: false, showDisclaimer: false, showPrivacy: false})}}>
        <small>{contentHelper.referencesLabel(this.state.lang)}</small>
      </Button>
      &nbsp;&nbsp;
      <Button className="App-button" outline size="sm" onClick={()=>{this.setState({showReferences: false, showUserGuide: false, showDisclaimer: true, showPrivacy: false})}}>
        <small>{contentHelper.disclaimerLabel(this.state.lang)}</small>
      </Button>
      &nbsp;&nbsp;
      <Button className="App-button" outline size="sm" onClick={()=>{this.setState({showReferences: false, showUserGuide: false, showDisclaimer: false, showPrivacy: true})}}>
        <small>{contentHelper.privacyLabel(this.state.lang)}</small>
      </Button>
      </ButtonGroup>
      </div>
    );
  }

  getModals = () =>
  {
    return (
      <div>
      <Modal id="userguide" scrollable={true} centered={true} size="lg" fade={false} isOpen={this.state.showUserGuide} toggle={()=>{this.setState({showUserGuide: false})}}>
        <ModalHeader>
          {this.getHeader()}
        </ModalHeader>
        <UserGuideModalBody lang={this.state.lang} />
        <ModalFooter>
          <Button className="App-button"  outline size="sm" onClick={()=>{this.setState({showUserGuide: false})}}>Ok</Button>
        </ModalFooter>
      </Modal>

      <Modal id="references" centered={true} size="lg" fade={false} isOpen={this.state.showReferences} toggle={()=>{this.setState({showReferences: false})}}>
        <ModalHeader>
          {this.getHeader()}
        </ModalHeader>
        <ReferencesModalBody lang={this.state.lang} />
        <ModalFooter>
          <Button className="App-button"  outline size="sm" onClick={()=>{this.setState({showReferences: false})}}>OK</Button>
        </ModalFooter>
      </Modal>

      <Modal id="disclaimer" scrollable={true} centered={true} size="lg" fade={false} isOpen={this.state.showDisclaimer} toggle={()=>{this.setState({showDisclaimer: false})}}>
        <ModalHeader>
          {this.getHeader()}
        </ModalHeader>
        <DisclaimerModalBody lang={this.state.lang} />
        <ModalFooter>
          <Button className="App-button" outline size="sm" onClick={()=>{this.setState({showDisclaimer: false})}}>OK</Button>
        </ModalFooter>
      </Modal>

      <Modal id="privacy" scrollable={true} centered={true} size="lg" fade={false} isOpen={this.state.showPrivacy} toggle={()=>{this.setState({showPrivacy: false})}}>
        <ModalHeader>
          {this.getHeader()}
        </ModalHeader>
        <PrivacyModalBody lang={this.state.lang} />
        <ModalFooter>
          <Button className="App-button" outline size="sm" onClick={()=>{this.setState({showPrivacy: false})}}>OK</Button>
        </ModalFooter>
      </Modal>
      </div>
    );
  }

  checkLoginResponse = (response) =>
  {
    if (response.status===200)
    {
      console.info("Token: "+response.data.access_token); // validty could be checked regularly
      this.setState({userName: this.state.userNameTmp, hashTmp: null, showUserGuide: true});
    }
    else
    {
      this.notifyBadCredentials();
    }
  }

  notifyBadCredentials = () =>
  {
    this.setState({userName: null});
    toast.error("Bad credentials", {position: toast.POSITION.TOP_LEFT});
  }


  tryLogin = () =>
  {
    if (this.state.userNameTmp === null || this.state.userNameTmp === "" || this.state.hashTmp === null || this.state.hashTmp === "")
    {
      this.notifyBadCredentials();
      return;
    }

    if (debugMode === false)
    {
      console.info("Send login call to server");
      axios.post('/auth/token', {
          username: this.state.userNameTmp,
          hash: this.state.hashTmp
        })
        .then(this.checkLoginResponse)
        .catch(error => {console.log(error);this.notifyBadCredentials();})
    }
    else
    {
      console.info("Debug mode: Skip login call to server");
      const fakeResp={};
      fakeResp.status=200;
      fakeResp.data={};
      fakeResp.data.access_token="feur";
      this.checkLoginResponse(fakeResp);
    }
  }

  render = () =>
  {
    const chartSeries=this.buildChartSeries(this.state.weatherData,this.state.mailleId);
    //const chartSeries=(this.state.chartSeries!==null&&this.state.mailleId!=null)?this.state.chartSeries[this.state.mailleId]:this.buildChartSeries(null,null);

    //console.info(chartSeries.temperatures);

    // const colorOfTS = (entry) =>
    // {
    //   return entry.resbool?"rgba(255,0,0,1)":"rgba(0,255,0,1)";
    // };
    //
    // const plotHeight=window.innerHeight/2.5;

    if (this.state.userName === null)
    {
      return (
        <div className="App">
          <ToastContainer />
          {this.getHeader()}
          <span>
            <small>
            <b>
            {debugMode?"DEBUG":""}
            </b>
            </small>
          </span>
          <br/>
          <br/>
          <b>Login</b>
          <br/>
          <br/>
          <form className="form-login">
            <div>
              <label id="loginLabel" style={{margin: "10px"}}>
                Identifier:
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <input name="login" type="text"
                        style={{textAlign: "left"}}
                        defaultValue={""}
                        onChange={ (e) => this.setState({userNameTmp: e.target.value}) }
                />
              </label>
            </div>
            <div>
              <label id="pwdLabel" style={{margin: "10px"}}>
                Password:
                &nbsp;&nbsp;
                <input name="password" type="password"
                        style={{textAlign: "left"}}
                        defaultValue={""}
                        onChange={ (e) => {
                                              const raw=e.target.value;
                                              this.setState({hashTmp: bcrypt.hashSync(raw,saltRounds)});
                                          }
                                 }
                />
              </label>
            </div>
          </form>

          <br/>

          <div>
            <Button className="App-button" outline color="primary" size="sm" onClick={ () => this.tryLogin() }>Connect</Button>
          </div>
          <br/>
          <br/>
          {this.getButtonsGroup()}
          {this.getModals()}
          <footer className="App-footer">
            &copy;Copyright 2021-2024 Luxembourg Institute of Science and Technology
            (<a className="App-link" rel="noopener noreferrer" target="_blank" href="http://www.list.lu/">LIST</a>).
          </footer>
        </div>
      );
    }

    // const TabRes = () => {
    //   return (
    //     <Histogram
    //         startTimeStamp={this.startTimestamp(this.state.weatherData,this.state.mailleId)}
    //         endTimeStamp={this.endTimestamp(this.state.weatherData,this.state.mailleId)}
    //         chartColorFunction={colorOfTS}
    //         tooltipFunction={(entry)=>{return entry.resbool?"Expected activity of insects!":"No activity of insects expected";}}
    //         chartLabel={contentHelper.resultsText(this.state.lang)}
    //         chartHeight={plotHeight}
    //         chartData={chartSeries.results} />
    //   )
    // }

    // const TabTemp = () => {
    //   return (
    //     <TimeSeriesChart
    //         startTimeStamp={this.startTimestamp(this.state.weatherData,this.state.mailleId)}
    //         endTimeStamp={this.endTimestamp(this.state.weatherData,this.state.mailleId)}
    //         chartColorFunction={colorOfTS}
    //         tooltipFunction={(entry)=>{return entry.value+"C°";}}
    //         chartLabel={contentHelper.temperaturesText(this.state.lang)}
    //         chartUnit='C°'
    //         chartHeight={plotHeight}
    //         chartData={chartSeries.temperatures} />
    //   )
    // }
    //
    // const TabTempSoil = () => {
    //   return (
    //     <TimeSeriesChart
    //         startTimeStamp={this.startTimestamp(this.state.weatherData,this.state.mailleId)}
    //         endTimeStamp={this.endTimestamp(this.state.weatherData,this.state.mailleId)}
    //         chartColorFunction={colorOfTS}
    //         tooltipFunction={(entry)=>{return entry.value+"C°";}}
    //         chartLabel={contentHelper.temperaturessoilText(this.state.lang)}
    //         chartUnit='C°'
    //         chartHeight={plotHeight}
    //         chartData={chartSeries.temperaturessoil} />
    //   )
    // }
    //
    // const TabPrecip = () => {
    //   return (
    //     <TimeSeriesChart
    //         startTimeStamp={this.startTimestamp(this.state.weatherData,this.state.mailleId)}
    //         endTimeStamp={this.endTimestamp(this.state.weatherData,this.state.mailleId)}
    //         chartColorFunction={colorOfTS}
    //         tooltipFunction={(entry)=>{return entry.value+"mm";}}
    //         chartLabel={contentHelper.precipitationsText(this.state.lang)}
    //         chartUnit='mm'
    //         chartHeight={plotHeight}
    //         chartData={chartSeries.precipitations} />
    //   )
    // }
    //
    // const TabWindspeed = () => {
    //   return (
    //     <TimeSeriesChart
    //         startTimeStamp={this.startTimestamp(this.state.weatherData,this.state.mailleId)}
    //         endTimeStamp={this.endTimestamp(this.state.weatherData,this.state.mailleId)}
    //         chartColorFunction={colorOfTS}
    //         tooltipFunction={(entry)=>{return entry.value+"m/s";}}
    //         chartLabel={contentHelper.windspeedText(this.state.lang)}
    //         chartUnit='m/s'
    //         chartHeight={plotHeight}
    //         chartData={chartSeries.windspeed} />
    //   )
    // }
    //
    // const TabSunshine = () => {
    //   return (
    //     <TimeSeriesChart
    //         startTimeStamp={this.startTimestamp(this.state.weatherData,this.state.mailleId)}
    //         endTimeStamp={this.endTimestamp(this.state.weatherData,this.state.mailleId)}
    //         chartColorFunction={colorOfTS}
    //         tooltipFunction={(entry)=>{return entry.value+"h";}}
    //         chartLabel={contentHelper.sunshinedurationText(this.state.lang)}
    //         chartUnit='h'
    //         chartHeight={plotHeight}
    //         chartData={chartSeries.sunshineduration} />
    //   )
    // }

    const TabStackedCharts = () => {
      return (
        <StackedChart timeseries={[
                        {
                          label: contentHelper.temperaturessoilText(this.state.lang),
                          data: chartSeries.temperaturessoil,
                          color: 'black',
                          unit: '°C'
                        },
                        {
                          label: contentHelper.temperaturesText(this.state.lang),
                          data: chartSeries.temperatures,
                          color: 'red',
                          unit: '°C'
                        },
                        {
                          label: contentHelper.sunshinedurationText(this.state.lang),
                          data: chartSeries.sunshineduration,
                          color: 'orange',
                          unit: ' H'
                        },
                        {
                          label: contentHelper.windspeedText(this.state.lang),
                          data: chartSeries.windspeed,
                          color: 'lightblue',
                          unit: ' m/s'
                        }]}
                      barcharts={[
                        {
                          label: contentHelper.precipitationsText(this.state.lang),
                          data: chartSeries.precipitations,
                          color: 'blue',
                          unit: 'mm'
                        },
                        {
                          label: contentHelper.resultsText(this.state.lang),
                          data: chartSeries.results,
                          color: 'rgba(255,0,0,1)'
                        }
                      ]}
                      resultsLabel = {contentHelper.resultsText(this.state.lang)}
                      leftYAxisLabel = {contentHelper.temperaturesText(this.state.lang) + " [ °C ] / " +
                                        contentHelper.windspeedText(this.state.lang) + " [ m/s ]"}
                      rightYAxisLabel = {contentHelper.precipitationsText(this.state.lang) + " [ mm ]"}>
        </StackedChart>
      )
    }

    /*const tabs = [
      {
        label: 'StackedCharts',
        Component: TabStackedCharts
      }
    ]*/

    return (
      <div className="App">

        <ToastContainer />

        <section style={{margin: '0 auto', height: '100%', width: '100%', padding: '0'}}>

        <Container style={{height: '100%', width: '100%', padding: '0'}}>

          <Row style={{margin: '0', height: '100%', width: '100%', padding: '0'}}>
            <Col style={{margin: '0', padding: '0'}}>
              <br/>
              <Map style={{margin: '0', minHeight: (window.innerHeight*0.9)+"px", width: "100%"}}
                    id='map'
                    ref={map => {this.map = map;}}
                    onClick={this.onMapClick}
                    center={[centerLatitude, centerLongitude]}
                    doubleClickZoom={false}
                    zoomControl={true}
                    zoomAnimation={false}
                    scrollWheelZoom={false}
                    trackResize={true}
                    preferCanvas={true}
                    dragging={true}
                    zoomSnap={this.state.zoomSnap}
                    zoom={this.state.zoom}>
                <TileLayer url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"  />
                <Marker position={[this.state.latitude, this.state.longitude]}/>
                {this.buildStationsMarkers()}

                <Control position="bottomleft" >
                  <Button outline style={{width: "40px"}} color="secondary" size="xs" onClick={ () => {if (this.map!==null) this.map.leafletElement.setView(new L.LatLng(centerLatitude,centerLongitude),defaultZoom);} }>
                    <FontAwesomeIcon size="1x" icon={faSync} />
                  </Button>
                </Control>

              </Map>
            </Col>
            <Col style={{margin: '0', padding: '0'}}>
              {this.getHeader()}
              <span>
                <small>
                <b>
                {debugMode?"DEBUG":(this.state.userName !== null? "User: "+this.state.userName : "")}
                </b>
                </small>
                <Button outline style={{width: "40px"}} color="secondary" size="xs" onClick={ () => this.setState({userName: null}) }>
                  <FontAwesomeIcon size="1x" icon={faSignOutAlt} />
                </Button>
              </span>
              <div style={{height: "10px"}}><br/></div>

              <br/><br/>
              <form className="form-basic">
                <div className="form-row">
                  <label style={{margin: "0px"}}>
                    {contentHelper.nearestStationLabel(this.state.lang)}
                    <FontAwesomeIcon id="nearestWeatherStationLabel" size="1x" icon={faInfoCircle} />
                    <span style={{textAlign: 'left', width: "100px"}}>{this.nearestStationName()}</span>
                  </label>
                  <UncontrolledTooltip placement="bottom" target="nearestWeatherStationLabel">
                    {contentHelper.weatherStationDesc(this.state.lang)}
                  </UncontrolledTooltip>
                </div>
                <div className="form-row">
                  <label style={{margin: "0px"}}>
                    {contentHelper.forecastDateLabel(this.state.lang)}
                    <FontAwesomeIcon id="forecastDateLabel" size="1x" icon={faInfoCircle} />
                    &nbsp;&nbsp;
                    <DatePicker
                            name="forecastDateDiv"
                            dateFormat="dd/MM/yyyy"
                            onChange={(val)=>{this.setState({forecastDateAsMoment: moment(val)});this.loadForecastData(moment(val).format('DDMMYYYY'));}}
                            selected={this.state.forecastDateAsMoment.toDate()}
                    />
                  </label>
                  <UncontrolledTooltip placement="bottom" target="forecastDateLabel">
                    {"Date"}
                  </UncontrolledTooltip>
                </div>
              </form>
              <br/><br/>

              {/* <hr/> */}

              <LoadingOverlay styles={{ spinner: (base) => ({...base,width: '200px','& svg circle': {stroke: 'rgba(0,0,255,0.5)'}})}} active={this.state.loading} spinner text='' >
              </LoadingOverlay>

              <TabStackedCharts/>

              {/* <Tabs tabs={tabs} orientation={"horizontal"}/> */}

              {this.getButtonsGroup()}

            </Col>
          </Row>

          </Container>

        </section>

        {this.getModals()}

        <footer className="App-footer">
          &copy;Copyright 2021-2024 Luxembourg Institute of Science and Technology
          (<a className="App-link" rel="noopener noreferrer" target="_blank" href="http://www.list.lu/">LIST</a>).
        </footer>
      </div>
    );
  }
}



//export default geolocated({
//    positionOptions:
//    {
//        enableHighAccuracy: false,
//    }
//})(App);

export default App;
