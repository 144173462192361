//
// Copyright 2021-2023 Luxembourg Institute of Science and Technology (LIST - http://www.list.lu/).
//
// Author: Olivier Parisot (olivier.parisot@list.lu)
//

import React from 'react';

import { ModalBody } from 'reactstrap';

import contentHelper from './contentHelper.js';


const userguideFR=
            (
            <ModalBody>
            <small>
            {contentHelper.appDesc(contentHelper.fr())}
            <br/>
            <br/>
            <h5>Guide d’utilisation</h5>
            Pour obtenir les prévisions, veuillez sélectionner la position de votre parcelle sur la carte située sur la gauche après la fermeture de cette fenêtre.
            <br/>
            <h5>Biologie des des ravageurs de la tige du colza</h5>
            Le charançon de la tige du chou (<i>Ceutorhynchus pallidactylus</i>) ainsi que le charançon de la tige du colza (<i>Ceutorhynchus napi</i>) sont des ravageurs de la tige d'environ 3 à 4 mm de long. Ce sont des ravageurs importants du colza mais aussi d'autres espèces de Brassicacées comme le chou, la navette, la moutarde noire. La biologie des deux charançons est assez différente. Le charançon de la tige du colza est celui qui présente le plus grand potentiel de pertes de rendement. L'insecte hiverne dans le sol dans un petit cocon à 3 cm sous la surface dans les champs avec un précédent colza. Les adultes éclosent du sol en février/mars à des températures > 12 °C et migrent vers les cultures de colza. En fonction de la température, les femelles peuvent commencer à pondre rapidement dans la tige principale (parfois dans les rameaux latéraux) quelques heures après la migration. Les larves creusent des galeries dans la moelle de la plante. En raison d'indicateurs chimiques, le tissu végétal peut se diviser au niveau de la zone de ponte, créant un point d’accès pour le phoma (<i>Phoma lingam</i>) responsable de nécroses. En juin/juillet, les larves matures du troisième stade quittent la tige par des trous de sortie pour se nymphoser dans le sol jusqu'au printemps.
            <br/>
            <br/>
            Le charançon de la tige du chou est le moins important des deux ravageurs. Les adultes sont de couleur brune à noire avec une tache blanchâtre dorsale entre le thorax et l’abdomen. Les adultes migrent de leurs lieux d'hivernage (sous la litière de feuilles à la lisière des bois ou des arbustes) vers les cultures d'oléagineux en février/mars à des températures > 12 °C. Les femelles se nourrissent de feuilles plusieurs jours pour la maturation ovarienne. De mars à mai, ils déposent des lots de 4 à 6 œufs translucides dans de petites poches créées par des morsures au niveau des pétioles et des nervures médianes des feuilles. Les larves sont de couleur blanche à jaunâtre et ont une capsule céphalique brune. Au cours des premier et deuxième stades, ils se nourrissent dans la moelle des pétioles. Les larves du troisième stade creusent dans les tiges principales des plantes. En juin/juillet, les larves matures du troisième stade quittent la tige par des trous de sortie pour se nymphoser à 2-3 cm de profondeur dans le sol. Selon les conditions météorologiques, la nymphose prend environ 20 à 30 jours. Les charançons adultes émergent du sol fin juin ou début juillet. Après s'être nourris de Brassicacées sauvages et cultivées ou de colza vivace pendant quatre semaines, ils migrent vers leurs sites d'hivernage. Les deux espèces apparaissent en même temps dans les cultures de colza et peuvent être facilement détectées dans des cuvettes jaunes. Le seuil économique est atteint et une application chimique doit être réalisée lorsqu'en moyenne dix individus d'une espèce de charançon sont capturés dans le piège en trois jours.
            </small>
            <br/>
            <br/>
            <img src={process.env.PUBLIC_URL+'/stem.jpg'} alt="stem" style={{width: "600px"}} />
            </ModalBody>
            );

const userguideDE=
            (
            <ModalBody>
            <small>
            {contentHelper.appDesc(contentHelper.de())}
            <br/>
            <br/>
            <h5>Bedienungshinweise</h5>
            Für eine Vorhersage klicken Sie auf den Standort ihres Schlages auf der Karte, nachdem Sie dieses Fenster geschlossen haben.
            <br/>
            <br/>
            <h5>Die Lebensweise der Stängelschädlinge</h5>
            Der Gefleckte Kohltriebrüssler (<i>Ceutorhynchus pallidactylus</i>) und der Große Rapsstängelrüssler (<i>Ceutorhynchus napi</i>) sind so genannte Rüsselkäfer und zwischen 3-4 mm groß. Sie können eine Reihe sehr unterschiedlicher Wirtspflanzen befallen, darunter Raps, Rübsen, Senf, Ölrettich und verschiedene Unkräuter, die mit dem Raps verwandt sind, darunter Hirtentäschel und Ackerhellerkraut. Obwohl sich beide Rüsselkäfer vom Aussehen sehr ähnlich sind, so ist doch ihre Lebensweise sehr unterschiedlich.
            <br/>
            Der Große Rapsstängelrüssler ist derjenige von den beiden, der die höchsten Ertragsschäden verursacht. Er überwintert in Kokons im Boden der letztjährigen Rapsschläge, etwa 3 cm tief unter der Bodenoberfläche. Die Käfer schlüpfen bei Temperaturen um 12°C, als Februar/März und wandern in die diesjährigen Rapsschläge ein. Die Ablage der Eier erfolgt in den Haupttrieb, bzw. bei vorgerücktem Längenwachstum in die Seitentriebe, teilweise bereits 24 Stunden nach der Einwanderung ins Feld. Als Folge der Ei-Ablage kann sich der Haupttrieb S-förmig verformen, bzw. der Länge nach aufreißen. Dadurch entstehen Eintrittspforten für Stängelphoma (Phoma lingam). Die Larven minieren im Haupttrieb und erzeugen typische Fraßgänge, teilweise mit Verbräunungen. Im Juni bohren sich die Larven aus dem Pflanzengewebe aus und wandern in den Boden ein, um sich zu verpuppen. Dort ruhen sie dann bis zum zeitigen Frühjahr.
            <br/>
            Der Gefleckte Kohltriebrüssler ist von der Schadwirkung geringer als sein „großer Bruder“. Er tritt jedoch teilweise in hoher Zahl auf, so dass seine Bekämpfung unerlässlich ist. Der Käfer ist einfach zu erkennen. Er hat einen weißen Fleck auf den Flügeldecken und seine Füße sind rötlich. Der Kohltriebrüssler überwintert in der Streuschicht unter dem Laub am Waldrand. Bei Temperaturen von >12 °C und Sonnenschein wandert er in die diesjährigen Rapsfelder ein. Die Weibchen müssen erst einen Reifungsfraß vollenden, indem sie an den jungen Rapsblättern fressen. Dieser Fraßschaden ist wirtschaftlich unbedeutend. Der eigentliche Schaden geht von den Larven aus. Dazu legen die Weibchen ab März bis zu 4 Eier in eine kleine Tasche, die sie in das Pflanzengewebe fressen. Typischerweise erfolgt die Eiablage in die Blattstiele, bzw. in die unterseitige Blattmittelrippe. Manchmal könne die Pflanzen durch die Bildung von Wundkallus diese Eier aus dem Gewebe herausdrücke und so bis zu 60% der Eier vernichten. Die Larven von weiß-gelblicher Farbe und haben eine braune Kopfkapsel. Während der ersten beiden Entwicklungsstadien minieren die Larven in den Blattstielen, das dritte und letzte Larvenstadium jedoch wandert in den Haupttrieb der Rapspflanzen ein. Im Juni bohren sich die Larven aus dem Pflanzengewebe aus und wandern in den Boden ein, um sich zu verpuppen. Dort ruhen sie etwa 30 Tage, bevor die Jungkäfer schlüpfen. Diese fressen noch einige Zeit am Altraps oder dem Raps verwandte Unkräuter, bevor sie zur Überwinterung in die Waldränder abwandern.
            <br/>
            Both species appear at the same time in crops of oilseed rape and can be easily detected by yellow water traps.
            <br/>
            Beide Arten müssen mit der Gelbschale im Feld erfasst werden. Der Bekämpfungsrichtwert ist erreicht, wenn von einer Art 10 Individuen innerhalb von 3 Tagen in der Gelbschale gefangen werden. Erst dann ist eine Insektizidmaßnahme wirtschaftlich.
            <br/>
            </small>
            <br/>
            <br/>
            <img src={process.env.PUBLIC_URL+'/stem.jpg'} alt="stem" style={{width: "600px"}} />
            </ModalBody>
            );

const userguideEN=
            (
              <ModalBody>
                <small>
                {contentHelper.appDesc(contentHelper.en())}
                <br/>
                <br/>
                <h5>User guide</h5>
                For taking advantage of the forecast, please select the location of your field on the map on the left hand side after closing this window.
                <br/>
                <br/>
                <h5>The biology of the stem mining weevils in oilseed crops</h5>
                <br/>
                The cabbage stem weevil (<i>Ceutorhynchus pallidactylus</i>) as well as the rape stem weevil (<i>Ceutorhynchus napi</i>) are stem miners about 3-4 mm long. Both species can exploit a large number of host plants, like oilseed rape, turnip rape, cabbage, black mustard, and several wild brassicaceous species. The biology of both weevils is quite different. The rape stem weevil is the one with higher potential for yield losses. The insect overwinter in last year’s rapeseed fields in the soil in a small cocoon, 3cm under the surface. The adults hatch from the soil in February/March at temperatures > 12 °C and migrate to crops of oilseed rape. Based on the temperature the females can start oviposition in the main stem (sometimes in the lateral racemes) rapidly within several hours after migration. The larvae are mining in the pith of the plant. Due to chemical cues the plant tissue can split at the oviposition area, building a port for ports for stem canker (<i>Phoma lingam</i>). In June/July, mature third instar larvae leave the stem through exit holes, to pupate in the soil up to spring.
                <br/>
                The cabbage stem weevil is the less important one of both pests. The adults are brown to black in colour with a patch of white scales in the centre of the wing covers. Adults migrate from their overwintering habitats (under leaf litter at the edges of woods or shrubs) into crops of oilseed in Feb/March at temperatures > 12 °C. Females feed on leaves several days for ovary maturation. During March to May they deposit batches of 4-6 translucent eggs into small pockets bitten into the petioles and midribs of leaves. Larvae are coloured white to yellowish and have a brown head capsule. During the first and second instar, they feed within the pith of petioles. Third instar larvae bore into the main stems of plants. In June/July, mature third instar larvae leave the stem through exit holes, to pupate 2-3cm deep in the soil. Depending on weather conditions pupation takes approximately 20-30 days. Adult weevils emerge from soil at the end of June or beginning of July. After feeding on wild and cultivated <i>Brassicaceae</i> or perennial oilseed rape for four weeks, they migrate to their overwintering sites. Both species appear at the same time in crops of oilseed rape and can be easily detected by yellow water traps. The economic threshold is reached and a chemical application should be done when on average ten individuals of one weevil species are caught in the trap within three days.
                </small>
                <br/>
                <br/>
                <img src={process.env.PUBLIC_URL+'/stem.jpg'} alt="stem" style={{width: "600px"}} />
              </ModalBody>
            );

const userguideLU=
            (
              <ModalBody>
                <small>
                {contentHelper.appDesc(contentHelper.lu())}
                <br/>
                <br/>
                <h5>Wéi geet dat? (Gebrauchsuweisung)</h5>
                Fir d’Prognosen ze gesinn, wielt wannechgelift d’Lokalisatioun vun ärem Feld op der Kaart lénkser Hand aus, nodeems dir dës Fënster zougemaach hutt.
                <br/>
                <br/>
                <h5>D’Biologie vun de Stängelschädlingen am Wanterraps</h5>
                <br/>
                De Kohlschotenrüssler (<i>Ceutorhynchus pallidactylus</i>) souwuel wéi och de Rapsstängelrüssler, si Stängelrüssler, déi 3-4mm grouss sinn. Béid Spezies kënnen eng large Gamme vu Wirtspflanze benotzen, wéi de Raps, de Rübsen, de Kabes, schwaarze Moschtert, an aner wëll <i>Brassicaceen</i>. Déi zwee Stängelrüssler hunn eng ganz verschidde Biologie. De Rapsstängelrüssler weist dat gréisste Potential fir Ertragsverloschter op. Dëst Insekt iwwerwantert am Buedem vun de Rapsstécker vum virege Joer, an enger klenger Popp, 3cm ënnert dem Buedem. Am Februar/Mäerz wann Temperaturen iwwert 12°C klammen, schlüpfen déi erwuesse Käfferen a migréiere bei déi aktuell Rapsfelder. Op Base vun der Temperatur kënnen déi weiblech Individue mat Eër leeën ufänken, dëst meeschtens am Haaptdriv (héiansdo a Säitendriwer) a ganz schnell innerhalb vun e puer Stonnen no der Migratioun. D’Larve buere sech an de Muerg vun der Planz. Chemesch Reaktioune splécken de Pflanzenstoff op der Ovipositiounsstell op an ët bilt sech sou eng Ouverture fir Stängelkriibs (Phoma lingam). Am Juni/Juli verloossen déi räif Larve vum drëtte Stadium de Stängel duerch Exitlächer, fir sech am Buedem bis d’Fréijoer ze verpoppen.
                <br/>
                De Kohlschotenrüssler ass de manner wichtege Stängelschädling vu béiden. Déi erwuesse Käffere si brong/schwaarz mat engem Fleck vu wäisse Schuppen am Zentrum vun de Fliggelofdeckungen. Déi erwuesse Käffere wandere vun hirer Iwwerwanterungsplaz (ënnert Reschter vu Blieder an Ecker vu Bëscher oder Hecken) an d’Kulture wéi de Raps am Februar/Mäerz wann Temperaturen iwwert 12°C leien. Fir e puer Deeg erniere sech déi weiblech Individue vu Blieder fir d‘Maturatioun vun den Eeërstäck. Vu Mäerz bis Mee leeë si Kéip vu 4-6 duerchsiichtegen Eeër a klengen Täschen an d’Blaatstiller an an déi mëttelst Rëppe vun de Blieder. D’Larve si wäiss giel gefierft an hunn eng brong Kappkapsel. Wärend dem éischten an dem zweete Stadium, erniere si sech vum Muerg vun de Blaatstiller. D‘Larven, déi den drëtte Stadium erreecht hunn, buere sech an den Haaptstill vun der Pflanz. Am Juni/Juli verloossen déi aus dem drëtte Stadium ausgeräift Larven de Stängel duerch Exitlächer, fir sech 2-3cm ënnert dem Buedem ze verpoppen. Ofhängeg vun de Wiederkonditiounen dauert d’Verpoppung zirka 20-30 Deeg. Déi erwuesse Käffere kommen Enn Juni oder Ufank Juli aus dem Buedem eraus. Nodeems se sech wärend Woche vu wëllen a kultivéierte <i>Brassicaceen</i> oder méijähregem Raps erniert hunn, migréiere si op hir Iwwerwanterungssite. Béid Spezies dauchen zur selwechter Zäit an de Rapskulturen op a kënnen einfach duerch eng giel Fangschuel agefaange ginn. Déi ekonomesch Schwell ass areecht an eng chemesch Applikatioun sollt gemaach ginn, wann an der Moyenne zéng Individue vun enger Stängelrüsslerart an enger Schuel innerhalb vun dräi Deeg opzefanne sinn.
                </small>
                <br/>
                <br/>
                <img src={process.env.PUBLIC_URL+'/stem.jpg'} alt="stem" style={{width: "600px"}} />
              </ModalBody>
            );

const UserGuideModalBody = ({ lang }) =>
{
  if (lang===contentHelper.en()) return (userguideEN);
  else if (lang===contentHelper.fr()) return (userguideFR);
  else if (lang===contentHelper.de()) return (userguideDE);
  else if (lang===contentHelper.lu()) return (userguideLU);
  else return <br/>;
};

export default UserGuideModalBody;
