//
// Copyright 2021-2023 Luxembourg Institute of Science and Technology (LIST - http://www.list.lu/).
//
// Author: Olivier Parisot (olivier.parisot@list.lu)
//

import React from 'react';

const LANGUAGE_EN="en";
const LANGUAGE_FR="fr";
const LANGUAGE_DE="de";
const LANGUAGE_LU="lu";

const supportedLanguages=[];
supportedLanguages.push(LANGUAGE_EN);
supportedLanguages.push(LANGUAGE_FR);
supportedLanguages.push(LANGUAGE_DE);
supportedLanguages.push(LANGUAGE_LU);


/**
 * contentHelper: fake React component providing static methods for content.
 *
 * Author: Olivier Parisot
 */
class contentHelper extends React.Component
{
  static isSupportedLang= (lang) =>
  {
    return supportedLanguages.includes(lang);
  }

  static defaultLang = () =>
  {
    return LANGUAGE_EN;
  }

  static en = () =>
  {
    return LANGUAGE_EN;
  }

  static de = () =>
  {
    return LANGUAGE_DE;
  }

  static fr = () =>
  {
    return LANGUAGE_FR;
  }

  static lu = () =>
  {
    return LANGUAGE_LU;
  }

  static appDesc = (lang) =>
  {
    if (lang===LANGUAGE_FR) return (
      <span>
      WEEVIL affiche une alerte lorsque l'on peut s'attendre à une activité des ravageurs miniers des tiges du genre <i>Ceutorhynchus</i> dans le colza d'hiver.
      </span>
    );
    else if (lang===LANGUAGE_DE) return (
      <span>
      WEEVIL zeigt eine Warnung an, wenn im Winterraps mit der Aktivität von Stängelminierern der Gattung <i>Ceutorhynchus</i> zu rechnen ist.
      Anhand meteorologischer Variablen gibt WEEVIL eine tägliche, regionale Vorhersage über den Zuflug der Stängelschädlinge (Großer Rapsstängelrüssler und Gefleckter Kohltriebrüssler) in die Rapsfelder.
      </span>
    );
    else if (lang===LANGUAGE_EN) return (
      <span>
      WEEVIL displays an alert when activity of stem mining pests from the genus <i>Ceutorhynchus</i> can be expected in winter oilseed rape.
      </span>
    );
    else if (lang===LANGUAGE_LU) return (
      <span>
      WEEVIL schéckt eng Alarm wann ze erwaarden ass, dass d’Stängelschädlinge vum Genre <i>Ceutorhynchus</i> am Wanterraps aktiv sinn.
      </span>
    );

    else return <br/>;
  }

  static referencesLabel = (lang) =>
  {
    if (lang===LANGUAGE_EN) return (
      "References"
    );
    else if (lang===LANGUAGE_FR) return (
      "Références"
    );
    else if (lang===LANGUAGE_DE) return (
      "Referenzen"
    );
    else if (lang===LANGUAGE_LU) return (
      "Referenzen"
    );
    else return "";
  }

  static userGuideLabel = (lang) =>
  {
    if (lang===LANGUAGE_EN) return (
      "User guide"
    );
    else if (lang===LANGUAGE_FR) return (
      "Guide d'utilisation"
    );
    else if (lang===LANGUAGE_DE) return (
      "Bedienungshinweise"
    );
    else if (lang===LANGUAGE_LU) return (
      "Gebrauchsuweisung"
    );
    else return "";
  }

  static disclaimerLabel = (lang) =>
  {
    if (lang===LANGUAGE_EN) return (
      "Legal notice"
    );
    else if (lang===LANGUAGE_FR) return (
      "Mentions légales"
    );
    else if (lang===LANGUAGE_DE) return (
      "Rechtliche Hinweise"
    );
    else if (lang===LANGUAGE_LU) return (
      "Mentions légales"
    );
    else return "";
  }

  static privacyLabel = (lang) =>
  {
    if (lang===LANGUAGE_EN) return (
      "Privacy"
    );
    else if (lang===LANGUAGE_FR) return (
      "Données personnelles"
    );
    else if (lang===LANGUAGE_DE) return (
      "Datenschutzerklärung"
    );
    else if (lang===LANGUAGE_LU) return (
      "Données personnelles"
    );
    else return "";
  }

  static nearestStationLabel = (lang) =>
  {
      if (lang===LANGUAGE_EN) return (
        <span>Nearest weather station:</span>
      );
      else if (lang===LANGUAGE_FR) return (
        <span>Station météo la plus proche:</span>
      );
      else if (lang===LANGUAGE_DE) return (
        <span>Nächste Wetterstation:</span>
      );
      else if (lang===LANGUAGE_LU) return (
        <span>Nächst Wiederstatioun:</span>
      );
      else return <br/>;
  }

  static forecastDateLabel = (lang) =>
  {
      if (lang===LANGUAGE_EN) return (
        <span>Forecast date:</span>
      );
      else if (lang===LANGUAGE_FR) return (
        <span>Date de départ des prédictions:</span>
      );
      else if (lang===LANGUAGE_DE) return (
        <span>Startdatum der Prognose:</span>
      );
      else if (lang===LANGUAGE_LU) return (
        <span>Prognose Startdatum:</span>
      );
      else return <br/>;
  }

  static resultsText = (lang) =>
  {
      if (lang===LANGUAGE_EN) return "Results";
      else if (lang===LANGUAGE_FR) return "Résultats";
      else if (lang===LANGUAGE_DE) return "Ergebnis";
      else if (lang===LANGUAGE_LU) return "Resultater";
      else return "";
  }

  static temperaturesText = (lang) =>
  {
      if (lang===LANGUAGE_EN) return "Temperatures";
      else if (lang===LANGUAGE_FR) return "Températures";
      else if (lang===LANGUAGE_DE) return "Temperaturen";
      else if (lang===LANGUAGE_LU) return "Temperaturen";
      else return "";
  }

  static temperaturessoilText = (lang) =>
  {
      if (lang===LANGUAGE_EN) return "Soil temperatures";
      else if (lang===LANGUAGE_FR) return "Températures du sol";
      else if (lang===LANGUAGE_DE) return "Bodentemperatur";
      else if (lang===LANGUAGE_LU) return "Temperaturen vum Buedem";
      else return "";
  }

  static precipitationsText = (lang) =>
  {
      if (lang===LANGUAGE_EN) return "Precipitations";
      else if (lang===LANGUAGE_FR) return "Précipitations";
      else if (lang===LANGUAGE_DE) return "Niederschlagsmengen";
      else if (lang===LANGUAGE_LU) return "Wévill huet et gerent";
      else return "";
  }

  static windspeedText = (lang) =>
  {
      if (lang===LANGUAGE_EN) return "Wind speed";
      else if (lang===LANGUAGE_FR) return "Vitesse du vent";
      else if (lang===LANGUAGE_DE) return "Windgeschwindigkeit";
      else if (lang===LANGUAGE_LU) return "Wandvitesse";
      else return "";
  }

  static sunshinedurationText = (lang) =>
  {
      if (lang===LANGUAGE_EN) return "Sunshine duration";
      else if (lang===LANGUAGE_FR) return "Durée d'ensoleillement";
      else if (lang===LANGUAGE_DE) return "Sonnenscheindauer";
      else if (lang===LANGUAGE_LU) return "Sonnenscheindauer";
      else return "";
  }

  static weatherStationDesc = (lang) =>
  {
      if (lang===LANGUAGE_EN) return (
        <small>
        Development is estimated using data from the closest weather station.
        </small>
      );
      else if (lang===LANGUAGE_FR) return (
        <small>
        Le développement est estimé à partir des données de la station météorologique la plus proche.
        </small>
      );
      else if (lang===LANGUAGE_DE) return (
        <small>
        Der Verlauf wird mit Daten der nächstgelegenen Wetterstation geschätzt.
        </small>
      );
      else if (lang===LANGUAGE_LU) return (
        <small>
        De Verlaf gëtt mat den Donnée’en vun der nächster Wiederstatioun geschätzt.
        </small>
      );
      else return <br/>;
  }




  render = () =>
  {
    return (<br/>);
  }
}

export default contentHelper;
